import { fromJson } from "kreate-common/modules/json-utils";

import { ResourceKey } from "../../types";

import { GetUser$Params, Result } from "./typing";

export async function httpGet(url: string) {
  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}

export function getResourceKey(
  params: GetUser$Params | null | undefined
): ResourceKey | undefined {
  if (!params) return undefined;
  return ["d3d7bf03-0ae9-4d72-9b76-3839fa3e79cb", params];
}

export const httpGet$GetUser = httpGet;
export const getResourceKey$GetUser = getResourceKey;
