import * as RxDialog from "@radix-ui/react-dialog";
import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  title: React.ReactNode;
  description?: React.ReactNode;
  slotLeft?: React.ReactNode;
  slotRight?: React.ReactNode;
};

export default function HeaderV2({
  className,
  style,
  title,
  description,
  slotLeft,
  slotRight,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <RxDialog.Title asChild>
        <div className={styles.title}>
          <Flex.Row
            flex="1 1 0"
            minWidth="0"
            justifyContent="stretch"
            alignItems="center"
          >
            {slotLeft ? (
              <Flex.Row
                justifyContent="center"
                alignContent="center"
                flex="0 0 auto"
              >
                {slotLeft}
              </Flex.Row>
            ) : null}
            <Flex.Cell flex="1 1 0" minWidth="0">
              {title}
            </Flex.Cell>
            {slotRight ? (
              <Flex.Row
                justifyContent="center"
                alignContent="center"
                flex="0 0 auto"
              >
                {slotRight}
              </Flex.Row>
            ) : null}
          </Flex.Row>
        </div>
      </RxDialog.Title>
      {description ? (
        <RxDialog.Description asChild>
          <div className={styles.description}>{description}</div>
        </RxDialog.Description>
      ) : undefined}
    </div>
  );
}
