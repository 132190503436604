import React from "react";

export default function useIsUserAgentLikelyMobile(): boolean | undefined {
  const [isUserAgentLikelyMobile, setIsUserAgentLikelyMobile] = React.useState<
    boolean | undefined
  >();

  React.useEffect(() => {
    setIsUserAgentLikelyMobile(
      /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    );
  }, []);
  return isUserAgentLikelyMobile;
}
