import { StaticImageData } from "next/image";

import { WalletName } from "../cardano-wallet/types";

import logo_eternl from "./assets/eternl.png";
import logo_flint from "./assets/flint.svg";
import logo_gerowallet from "./assets/gerowallet.png";
import logo_lace from "./assets/lace.svg";
import logo_nami from "./assets/nami.svg";
import logo_typhoncip30 from "./assets/typhoncip30.svg";
import logo_unknown from "./assets/unknown.svg";
import logo_vespr from "./assets/vespr.webp";

// Unsupported wallets:
// import logo_cardwallet from "./assets/cardwallet.png";
// import logo_nufi from "./assets/nufi.svg";

export type WalletInfo = {
  name: WalletName;
  title: string;
  image: StaticImageData;
  url: string | undefined;
  supported: boolean;
};

export const ALL_KNOWN_WALLETS: WalletInfo[] = [
  {
    name: "nami",
    title: "Nami",
    image: logo_nami,
    url: "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo",
    supported: true,
  },
  {
    name: "eternl",
    title: "Eternl",
    image: logo_eternl,
    url: "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka",
    supported: true,
  },
  {
    name: "lace",
    title: "Lace",
    image: logo_lace,
    url: "https://chromewebstore.google.com/detail/lace/gafhhkghbfjjkeiendhlofajokpaflmk",
    supported: true,
  },
  {
    name: "flint",
    title: "Flint",
    image: logo_flint,
    url: "https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj",
    supported: true,
  },
  {
    name: "typhoncip30",
    title: "Typhon",
    image: logo_typhoncip30,
    url: "https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh",
    supported: true,
  },
  {
    name: "gerowallet",
    title: "Gero Wallet",
    image: logo_gerowallet,
    // isNetworkMainnet
    //           ? "https://chrome.google.com/webstore/detail/gerowallet/bgpipimickeadkjlklgciifhnalhdjhe"
    //           : "https://chrome.google.com/webstore/detail/gerowallet-preview/iifeegfcfhlhhnilhfoeihllenamcfgc"
    url: "https://chrome.google.com/webstore/detail/gerowallet/bgpipimickeadkjlklgciifhnalhdjhe",
    supported: true,
  },
  {
    name: "vespr",
    title: "Vespr",
    image: logo_vespr,
    url: "https://play.google.com/store/apps/details?id=art.nft_craze.gallery.main",
    supported: true,
  },
];

export function getWalletInfo(walletName: WalletName): WalletInfo {
  const found = ALL_KNOWN_WALLETS.find((item) => item.name === walletName);
  return (
    found || {
      name: walletName,
      title: `Unknown wallet (${walletName})`,
      image: logo_unknown,
      url: undefined,
      supported: false,
    }
  );
}
