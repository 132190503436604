import cx from "clsx";
import * as React from "react";

import styles from "./index.module.scss";

const PADDING = {
  small: styles.paddingSmall,
  medium: styles.paddingMedium,
  none: "",
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
  padding?: keyof typeof PADDING;
};

export default function Content({
  className,
  style,
  content,
  children = content,
  padding = "medium",
}: Props) {
  return (
    <div
      className={cx(styles.container, className, PADDING[padding])}
      style={style}
    >
      {children}
    </div>
  );
}
