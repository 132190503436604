import { fromJson } from "kreate-common/modules/json-utils";

import { Params, Result } from "./typing";

export async function httpPost(url: string, params: Params): Promise<Result> {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
    body: JSON.stringify(params),
  });
  if (!response.ok) {
    throw new Error("response not ok", {
      cause: {
        status: response.status,
        statusText: response.statusText,
        json: await response.json().catch(() => undefined),
      },
    });
  }
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}

export const httpPost$AuthenticateByCardanoWallet = httpPost;
