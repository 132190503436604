import { useModalPromises } from "kreate-common/modules/kreate-ui/components/Modal/hoc/withModalPromises";

import ModalConfirm from ".";

export function useModalTrigger$ModalConfirm() {
  const { showModal } = useModalPromises();

  const openModal = async (
    message: React.ReactNode,
    otherProps?: Partial<
      Omit<
        React.ComponentProps<typeof ModalConfirm>,
        "onAccept" | "onReject" | "message" | "open"
      >
    >
  ) =>
    await showModal<boolean>(({ resolve }) => (
      <ModalConfirm
        open
        message={message}
        onAccept={() => resolve(true)}
        onReject={() => resolve(false)}
        {...otherProps}
      />
    ));

  return { openModal };
}
