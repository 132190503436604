import { z } from "zod";

export const BooleanField = z
  .union([
    z.literal("false"),
    z.literal("true"),
    z.literal(false),
    z.literal(true),
  ])
  .transform((value) => ["true", true].includes(value));
export type BooleanField = z.infer<typeof BooleanField>;

export const NullField = z.literal("null").transform(() => null);
export type NullField = z.infer<typeof NullField>;
