import { Cip30Handle } from "@hyperionbt/helios";
import cx from "clsx";
import { DisplayableError } from "kreate-common/modules/displayable-error";
import CircularProgress$Indeterminate from "kreate-common/modules/kreate-ui/components/CircularProgress/components/CircularProgress$Indeterminate";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import * as React from "react";

import styles from "./index.module.scss";

import { useCardanoWalletApi } from "@/modules/cardano-wallet";
import { WalletName } from "@/modules/cardano-wallet/types";
import { LogoWallet } from "@/modules/cardano-wallet-list/components/LogoWallet";
import { getWalletInfo } from "@/modules/cardano-wallet-list/utils";
import { useToast } from "@/modules/kreate-contexts/ToastContext";

type Props = {
  name: WalletName;
  disabled?: boolean;
  onBusyChange: (value: boolean) => void;
  onSuccess: (cip30Handle: Cip30Handle) => void;
};

export default function ButtonConnectWallet({
  name,
  disabled,
  onBusyChange,
  onSuccess,
}: Props) {
  const [isWalletSelected, setIsWalletSelected] = React.useState(false);
  const walletInfo = getWalletInfo(name);
  const { showMessage } = useToast();
  const api = useCardanoWalletApi();

  const connect = async () => {
    if (api.type !== "disconnected") {
      return;
    }
    onBusyChange(true);
    setIsWalletSelected(true);
    try {
      const cip30Handle = await api.connect(name);
      onSuccess(cip30Handle);
    } catch (error) {
      const displayableError = DisplayableError.from(error);
      showMessage({
        color: "danger",
        title: displayableError.title,
        description: displayableError.description,
      });
    } finally {
      setIsWalletSelected(false);
      onBusyChange(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isWalletAvailable = !!(window as any)?.cardano?.[name];

  return (
    <button
      disabled={disabled}
      className={cx(
        styles.buttonWallet,
        disabled ? styles.disabledButton : null
      )}
      onClick={() => {
        if (isWalletAvailable) {
          void connect();
        } else {
          window.open(walletInfo.url || "#", "_blank");
        }
      }}
    >
      <Flex.Row className={styles.heading} alignItems="center" gap="8px">
        {walletInfo.title}
        {isWalletSelected ? (
          <CircularProgress$Indeterminate size="14px" />
        ) : null}
      </Flex.Row>
      <div
        className={
          !isWalletAvailable && !isWalletSelected ? styles.link : undefined
        }
      >
        <LogoWallet walletName={name} size="24px" />
      </div>
    </button>
  );
}
