import * as Dialog from "@radix-ui/react-dialog";
import cx from "clsx";
import React from "react";

import { useModalConfig } from "../../hooks/useModalConfig";

import styles from "./index.module.scss";

const SIZE = {
  sm: styles.sizeSm,
  md: styles.sizeMd,
  lg: styles.sizeLg,
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  closeOnEscape?: boolean;
  closeOnDimmerClick?: boolean;
  size?: keyof typeof SIZE;
};

export default function Root({
  className,
  style,
  children,
  open,
  onOpen,
  onClose,
  closeOnEscape = true,
  closeOnDimmerClick = true,
  size = "md",
}: Props) {
  const { container } = useModalConfig();
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        open ? onOpen?.() : onClose?.();
      }}
    >
      <Dialog.Portal {...(container ? { container } : null)}>
        <div className={styles.portal}>
          <Dialog.Overlay className={styles.overlay} />
          <Dialog.Content
            className={cx(className, styles.content, SIZE[size])}
            style={style}
            onEscapeKeyDown={
              closeOnEscape ? undefined : (e) => e.preventDefault()
            }
            onPointerDownOutside={
              closeOnDimmerClick ? undefined : (e) => e.preventDefault()
            }
          >
            {children}
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
