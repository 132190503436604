import Image from "next/image";

import { getWalletInfo } from "../../utils";

import { WalletName } from "@/modules/cardano-wallet/types";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  walletName: WalletName;
  size?: string | number;
};

export function LogoWallet({
  className,
  style,
  walletName,
  size = "1em",
}: Props) {
  const walletInfo = getWalletInfo(walletName);

  return (
    <Image
      className={className}
      style={{ width: size, height: size, objectFit: "contain", ...style }}
      alt={walletName || "unknown"}
      src={walletInfo.image}
    />
  );
}
