import {
  UnixTimestamp,
  NonceTokenString,
} from "kreate-common/modules/business-types";
import { z } from "zod";

export const Payload = z.object({
  // handle token expiration
  expiration: UnixTimestamp,
});
export type Payload = z.infer<typeof Payload>;

export const PayloadV2 = z.object({
  nonce: NonceTokenString,
});
export type PayloadV2 = z.infer<typeof PayloadV2>;

export const SignData = z.object({
  signature: z.string(), // (address, challenge) signed by cardano wallet
  // for CIP-0030
  key: z.string().optional(),
});

export type SignData = z.infer<typeof SignData>;

export type WalletInfo = {
  addressBech32: string;
  publicKeyHash: string;
};

export type WalletInfoV2 = {
  addressBech32: string;
  publicKeyHash: string;
  payload: PayloadV2;
};

export type VerifySignResult = {
  addressBech32: string;
  publicKeyHash: string;
  payload: string;
};
