import ButtonOutline from "kreate-common/modules/kreate-ui/components/ButtonOutline";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import ModalV2 from "kreate-common/modules/kreate-ui/components/ModalV2";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  open: boolean;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  message: React.ReactNode;
  labelButtonAccept?: React.ReactNode;
  labelButtonReject?: React.ReactNode;
  onAccept?: () => void;
  onReject?: () => void;
};

/**
 * This modal resembles `window.confirm()`, used for simple use cases only.
 * For more complex use cases, please write your own modal.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/confirm
 */
export default function ModalConfirm({
  open,
  icon,
  title,
  message,
  labelButtonAccept = "OK",
  labelButtonReject = "Cancel",
  onAccept,
  onReject,
}: Props) {
  return (
    <ModalV2 open={open} onClose={onReject}>
      <ModalV2.Content>
        {icon ? <div className={styles.icon}>{icon}</div> : null}
        <Flex.Col gap="20px">
          {title ? <div className={styles.title}>{title}</div> : null}
          <div className={styles.message}>{message}</div>
        </Flex.Col>
      </ModalV2.Content>
      <ModalV2.Actions>
        <ButtonOutline
          size="md"
          content={labelButtonReject}
          onClick={onReject}
        />
        <ButtonPrimary
          size="md"
          content={labelButtonAccept}
          onClick={onAccept}
        />
      </ModalV2.Actions>
    </ModalV2>
  );
}
