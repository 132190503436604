import * as Dialog from "@radix-ui/react-dialog";
import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
  slotLeft?: React.ReactNode;
  slotRight?: React.ReactNode;
};

export default function Header({
  className,
  style,
  content,
  children = content,
  slotLeft,
  slotRight,
}: Props) {
  return (
    <Dialog.Title asChild>
      <div className={cx(styles.container, className)} style={style}>
        <Flex.Row flex="1 1 0" justifyContent="stretch" alignItems="center">
          {slotLeft ? <div className={styles.slot}>{slotLeft}</div> : null}
          <div className={styles.children}>{children}</div>
          {slotRight ? <div className={styles.slot}>{slotRight}</div> : null}
        </Flex.Row>
      </div>
    </Dialog.Title>
  );
}
