import { Cip30Handle } from "@hyperionbt/helios";
import ButtonLink from "kreate-common/modules/kreate-ui/components/ButtonLink";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import ModalV2 from "kreate-common/modules/kreate-ui/components/ModalV2";
import * as React from "react";
import { RxCross2 } from "react-icons/rx";

import ButtonConnectWallet from "./components/ButtonConnectWallet";
import styles from "./index.module.scss";

import { WalletName } from "@/modules/cardano-wallet/types";
import useIsUserAgentLikelyMobile from "@/modules/common-hooks/useIsUserAgentLikelyMobile";

type Props = {
  open: boolean;
  initialShowAllWallets?: boolean;
  onSuccess: (cip30Handle: Cip30Handle) => void;
  onCancel: () => void;
};

export default function ModalConnectWallet({
  open,
  initialShowAllWallets,
  onSuccess,
  onCancel,
}: Props) {
  const [busy, setBusy] = React.useState(false);
  const [showAllWallets, setShowAllWallets] = React.useState(
    initialShowAllWallets || false
  );

  const isUserAgentLikelyMobile = useIsUserAgentLikelyMobile();

  const walletList: WalletName[] = showAllWallets
    ? ["nami", "eternl", "lace", "flint", "typhoncip30", "gerowallet", "vespr"]
    : isUserAgentLikelyMobile
    ? ["vespr", "flint"]
    : ["nami", "eternl", "lace", "flint", "typhoncip30", "gerowallet"];

  return (
    <ModalV2 open={open} onClose={onCancel} size="sm">
      <ModalV2.Content padding="small">
        <Flex.Row justifyContent="space-between" alignItems="center">
          <Flex.Cell className={styles.title}>{"Connect Wallet"}</Flex.Cell>
          <button className={styles.buttonClose} onClick={onCancel}>
            <RxCross2 />
          </button>
        </Flex.Row>
        <Flex.Col style={{ marginTop: "24px" }} gap="8px">
          {walletList.map((walletName) => (
            <ButtonConnectWallet
              key={walletName}
              name={walletName}
              disabled={busy}
              onBusyChange={(value) => setBusy(value)}
              onSuccess={onSuccess}
            />
          ))}
          {!showAllWallets ? (
            <Flex.Row justifyContent="center">
              <ButtonLink
                content="View More"
                className={styles.buttonViewMore}
                onClick={() => setShowAllWallets(true)}
                alwaysUnderline
              />
            </Flex.Row>
          ) : undefined}
        </Flex.Col>
      </ModalV2.Content>
    </ModalV2>
  );
}
