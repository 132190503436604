import cx from "clsx";
import React from "react";

import Flex from "../Flex";

import styles from "./index.module.scss";

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  disabled?: boolean;
  alwaysUnderline: boolean;
};

type ForwardedProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type Props = OwnProps & Omit<ForwardedProps, keyof OwnProps>;

const ButtonLink: React.ForwardRefExoticComponent<Props> = React.forwardRef<
  HTMLButtonElement,
  Props
>(function ButtonLink(
  {
    className,
    style,
    content,
    children = content,
    icon,
    iconRight,
    alwaysUnderline,
    disabled,
    ...others
  }: Props,
  ref
) {
  return (
    <button
      ref={ref}
      className={cx(
        styles.container,
        className,
        alwaysUnderline ? styles.underline : undefined
      )}
      style={style}
      type="button"
      disabled={disabled}
      title={typeof children === "string" ? children : undefined}
      {...others}
    >
      <Flex.Row alignItems="center" gap="4px">
        {icon ? (
          <Flex.Cell flex="0 0 auto" className={styles.icon}>
            {icon}
          </Flex.Cell>
        ) : null}
        {children ? (
          <Flex.Cell flex="1 1 auto" minWidth="0">
            {children}
          </Flex.Cell>
        ) : null}
        {iconRight ? (
          <Flex.Cell flex="0 0 auto" className={styles.icon}>
            {iconRight}
          </Flex.Cell>
        ) : null}
      </Flex.Row>
    </button>
  );
});

export default ButtonLink;
