import cx from "clsx";
import React from "react";

import { withDefaultProps } from "../../hoc/withDefaultProps";
import CircularProgress$Indeterminate from "../CircularProgress/components/CircularProgress$Indeterminate";

import styles from "./index.module.scss";

type ClassName = string;

const SIZE = {
  inherit: "",
  xs: styles.sizeXs,
  "xs-square": styles.sizeXsSquare,
  sm: styles.sizeSm,
  "sm-square": styles.sizeSmSquare,
  md: styles.sizeMd,
  "md-square": styles.sizeMdSquare,
  lg: styles.sizeLg,
  "lg-square": styles.sizeLgSquare,
  xl: styles.sizeXl,
  "xl-square": styles.sizeXlSquare,
};

const COLOR = {
  inherit: "",
  primary: styles.colorPrimary,
  secondary: styles.colorSecondary,
};

const BORDER = {
  inherit: "",
  none: styles.borderNone,
  solid: styles.borderSolid,
};

const PADDING = {
  inherit: "",
  medium: styles.paddingMedium,
  small: styles.paddingSmall,
  minimal: styles.paddingMinimal,
};

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  loading?: boolean;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
  disabled?: boolean;
  /**
   * If onClick() is running, loading and disabled are automatically set to true.
   */
  smart?: boolean;

  // display props
  size?: keyof typeof SIZE | [ClassName];
  color?: keyof typeof COLOR | [ClassName];
  border?: keyof typeof BORDER | [ClassName];
  padding?: keyof typeof PADDING | [ClassName];
};

type ForwardedProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type Props = OwnProps & Omit<ForwardedProps, keyof OwnProps>;

export type CustomButton$Props = Props;

const CustomButton$Root: React.ForwardRefExoticComponent<Props> =
  React.forwardRef<HTMLButtonElement, Props>(function CustomButton$Root(
    {
      className,
      style,
      content,
      children = content,
      icon,
      iconRight,
      color = "secondary",
      size = "md",
      border = "none",
      padding = "medium",
      loading = false,
      onClick,
      disabled,
      smart = true,
      ...others
    }: Props,
    ref
  ) {
    const [running, setRunning] = React.useState(false);

    if (smart && running) {
      disabled = true;
      loading = true;
    }

    return (
      <button
        ref={ref}
        className={cx(
          styles.container,
          className,
          Array.isArray(color) ? color : COLOR[color],
          Array.isArray(size) ? size : SIZE[size],
          Array.isArray(border) ? border : BORDER[border],
          Array.isArray(padding) ? padding : PADDING[padding],
          loading ? styles.loading : null
        )}
        style={style}
        type="button"
        disabled={disabled}
        title={typeof children === "string" ? children : undefined}
        onClick={(event) => {
          const returnValue: unknown = onClick?.(event);
          if (smart && returnValue instanceof Promise) {
            setRunning(true);
            returnValue.finally(() => {
              setRunning(false);
            });
          }
        }}
        {...others}
      >
        <div className={styles.content}>
          {icon ? <div className={styles.icon}>{icon}</div> : null}
          {children ? <div className={styles.children}>{children}</div> : null}
          {iconRight ? <div className={styles.icon}>{iconRight}</div> : null}
          {loading ? (
            <div className={styles.circularProgressContainer}>
              <CircularProgress$Indeterminate size="24px" />
            </div>
          ) : null}
        </div>
      </button>
    );
  });

const CustomButton = Object.assign(CustomButton$Root, {
  withDefaultProps: (
    defaultProps: Partial<Props>
  ): React.ForwardRefExoticComponent<Props> =>
    withDefaultProps(defaultProps)(CustomButton$Root),
});

export default CustomButton;
