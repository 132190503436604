import React from "react";

import { useToast } from "@/modules/kreate-contexts/ToastContext";

/** @deprecated use `useWithErrorHandler` instead */
export function useBusyCount() {
  const [count, setCount] = React.useState(0);
  const { showError } = useToast();

  const withDefaultErrorHandler = function <T extends unknown[]>(
    task: (...args: T) => void | Promise<void>
  ) {
    return async (...args: T) => {
      try {
        setCount((count) => count + 1);
        await task(...args);
      } catch (error) {
        error && showError({ error });
      } finally {
        setCount((count) => count - 1);
      }
    };
  };

  const withCustomErrorHandler = (
    errorHandler: (error: unknown) => void | Promise<void>
  ) =>
    function <T extends unknown[]>(task: (...args: T) => void | Promise<void>) {
      return async (...args: T) => {
        try {
          setCount((count) => count + 1);
          await task(...args);
        } catch (error) {
          error && (await errorHandler(error));
        } finally {
          setCount((count) => count - 1);
        }
      };
    };

  return {
    busy: !!count,
    withDefaultErrorHandler,
    withCustomErrorHandler,
  };
}
