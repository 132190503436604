import {
  User,
  UserId,
  UrlHandleString,
} from "kreate-common/modules/business-types";
import { z } from "zod";

import { BooleanField } from "../../utils/customType";

export const Params = z
  .object({
    userId: UserId.optional(),
    handle: UrlHandleString.optional(),
    walletAddress: z.string().optional(),
    actorId: UserId.optional(),
    numMaxArtworks: z.coerce.number().optional(),
    returnCollections: BooleanField.optional(),
  })
  .refine((data) => !!data.userId || !!data.handle || !!data.walletAddress, {
    message: "Required userId or handle or walletAddress",
  });

export type Params = z.infer<typeof Params>;

export const Result = User;

export type Result = z.infer<typeof Result>;

export type GetUser$Params = Params;
export const GetUser$Params = Params;
export type GetUser$Result = Result;
export const GetUser$Result = Result;
