// When sending props from server to client, undefined fields are not supported
export function omitUndefinedRecursively<T>(value: T): T {
  if (value == null) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map((elem) => omitUndefinedRecursively(elem)) as T;
  }
  if (typeof value === "object" && value.constructor === Object) {
    return Object.fromEntries(
      Object.entries(value)
        .filter(([, value]) => value !== undefined)
        .map((elem) => omitUndefinedRecursively(elem))
    ) as T;
  }
  return value;
}

export function formatFallback(
  obj: Record<string, unknown>
): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined)
  );
}

export function intentionallyIgnoreError(error: unknown) {
  console.warn(error);
  return undefined;
}
