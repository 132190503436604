import cx from "clsx";
import React from "react";

type RequiredProps = {
  className?: string;
  style?: React.CSSProperties;
};

function mergeProps<A extends RequiredProps, B extends RequiredProps>(
  a: A,
  b: B
): A & B {
  return {
    ...a,
    ...b,
    className: cx(a.className, b.className),
    style: { ...a.style, ...b.style },
  };
}

type InstanceType<Props> = Props extends React.RefAttributes<infer T>
  ? T
  : unknown;

export function withDefaultProps<Props extends RequiredProps>(
  defaultProps: Partial<Props>
) {
  return function (WrappedComponent: React.ComponentType<Props>) {
    const WithDefaultProps = React.forwardRef<InstanceType<Props>, Props>(
      (props, ref) => (
        <WrappedComponent
          ref={ref}
          key={null}
          {...mergeProps(defaultProps, props)}
        />
      )
    );

    WithDefaultProps.displayName = `WithDefaultProps(${
      WrappedComponent.displayName || "..."
    })`;

    return WithDefaultProps;
  };
}
