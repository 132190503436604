import { z } from "zod";

import { SignData } from "../../utils/cardano/typing";

export const Params = z.object({
  walletAddress: z.string(),
  // for verifying wallet address
  walletSignData: z.object({
    signed: SignData,
  }),
});

export type Params = z.infer<typeof Params>;

export const Result = z.object({
  userId: z.string(),
  address: z.string(),
});

export type Result = z.infer<typeof Result>;

export type AuthenticateByCardanoWallet$Params = Params;
export const AuthenticateByCardanoWallet$Params = Params;
export type AuthenticateByCardanoWallet$Result = Result;
export const AuthenticateByCardanoWallet$Result = Result;
