import { withDefaultProps } from "../../hoc/withDefaultProps";
import CustomButton, { CustomButton$Props } from "../CustomButton";

import styles from "./index.module.scss";

type Props = CustomButton$Props & React.RefAttributes<HTMLButtonElement>;

export type ButtonOutline$Props = Props;

const ButtonOutline: React.ForwardRefExoticComponent<Props> =
  CustomButton.withDefaultProps({
    border: "solid",
    color: [styles.buttonColor],
  });

export default Object.assign(ButtonOutline, {
  withDefaultProps: (
    defaultProps: Partial<Props>
  ): React.ForwardRefExoticComponent<Props> =>
    withDefaultProps(defaultProps)(ButtonOutline),
});
