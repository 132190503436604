import ModalV2$Actions from "./components/Actions";
import ModalV2$Content from "./components/Content";
import ModalV2$Header from "./components/Header";
import ModalV2$HeaderV2 from "./components/HeaderV2";
import ModalV2$Root from "./components/Root";

const ModalV2 = Object.assign(ModalV2$Root, {
  Root: ModalV2$Root,
  Header: ModalV2$Header,
  HeaderV2: ModalV2$HeaderV2,
  Content: ModalV2$Content,
  Actions: ModalV2$Actions,
});

export default ModalV2;
