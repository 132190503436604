import { fromJson, toJson } from "kreate-common/modules/json-utils";

import { ChallengeRequest$Result, ChallengeRequest$Params } from "./typing";

export async function httpPost$ChallengeRequest(
  url: string,
  params: ChallengeRequest$Params
): Promise<ChallengeRequest$Result> {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: toJson(params),
  });
  if (!response.ok) {
    throw new Error(
      "response not ok: " + toJson({ status: response.status, url, params })
    );
  }

  const text = await response.text();
  const data = fromJson(text);
  const result = ChallengeRequest$Result.parse(data);
  return result;
}
