import { withDefaultProps } from "../../hoc/withDefaultProps";
import CustomButton, { CustomButton$Props } from "../CustomButton";

type Props = CustomButton$Props;

export type ButtonPrimary$Props = Props;

const ButtonPrimary = CustomButton.withDefaultProps({
  color: "primary",
});

export default Object.assign(ButtonPrimary, {
  withDefaultProps: (
    defaultProps: Partial<Props>
  ): React.ForwardRefExoticComponent<Props> =>
    withDefaultProps(defaultProps)(ButtonPrimary),
});
