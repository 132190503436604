import * as helios from "@hyperionbt/helios";
import { httpPost$AuthenticateByCardanoWallet } from "kreate-server/commands/AuthenticateByCardanoWallet/fetcher";
import { httpPost$ChallengeRequest } from "kreate-server/commands/ChallengeRequest/fetcher";
import { httpGet$GetUser } from "kreate-server/commands/GetUser/fetcher";
import { NextRouter } from "next/router";

import { intentionallyIgnoreError } from "../../utils";

import { getStakeAddressFromCip30Wallet } from "@/modules/cardano-wallet/utils";

export async function getUserFromCardanoWallet(
  cip30Wallet: helios.Cip30Wallet
) {
  const walletAddress = await getStakeAddressFromCip30Wallet(cip30Wallet);
  const user = await httpGet$GetUser(
    `/api/v1/user-by-address/${walletAddress}`
  ).catch(intentionallyIgnoreError);
  return user;
}

export async function authenticateByCardanoWallet(
  cip30Wallet: helios.Cip30Wallet
) {
  const walletHelper = new helios.WalletHelper(cip30Wallet);
  const baseAddressH = await walletHelper.baseAddress;
  const stakeAddressH = helios.StakeAddress.fromAddress(baseAddressH);

  const { nonce } = await httpPost$ChallengeRequest(
    `/api/v1/challenge/request`,
    { useCase: "authenticate" }
  );

  // NOTE: @kien-kreate: After the nonce, there MUST NOT be any digits.
  // Otherwise, the parsing process in BE might fail.
  const messageRaw = [
    "You are requesting to authenticate using the following wallet:",
    baseAddressH.toBech32(),
    "",
    "Stake address:",
    stakeAddressH.toBech32(),
    "",
    "Your verification code:",
    nonce,
  ].join("\n");

  const messageHex = helios.bytesToHex(helios.textToBytes(messageRaw));
  const dataSignature = await cip30Wallet.signData(
    // TODO: @kien-kreate: remove `as any` once `kreate-art/Helios` is updated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stakeAddressH as any,
    messageHex
  );

  return await httpPost$AuthenticateByCardanoWallet(
    "/api/v1/auth/authenticate-by-wallet",
    {
      walletAddress: stakeAddressH.toBech32(),
      walletSignData: { signed: dataSignature },
    }
  );
}

export async function safelyGoToRedirectedUrl(router: NextRouter) {
  const url = router.query.redirectUrl;
  if (typeof url === "string" && url.startsWith("/") && !url.startsWith("//")) {
    await router.push(url);
  } else {
    await router.push("/");
  }
}
