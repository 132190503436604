import {
  NonceTokenString,
  NonceUseCase,
} from "kreate-common/modules/business-types";
import { z } from "zod";

export const ChallengeRequest$Params = z.object({
  useCase: NonceUseCase,
});

export type ChallengeRequest$Params = z.infer<typeof ChallengeRequest$Params>;

export const ChallengeRequest$Result = z.object({
  nonce: NonceTokenString,
});

export type ChallengeRequest$Result = z.infer<typeof ChallengeRequest$Result>;
