import cx from "clsx";
import * as React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

export default function Actions({
  className,
  style,
  content,
  children = content,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      {children}
    </div>
  );
}
