import { CLIENT_ENV } from "kreate-env/client";
import Head from "next/head";

// Useful resources:
// https://nextjs.org/docs/api-reference/next/head

type Props = {
  title: string;
  description: string | null | undefined;
  imageUrl: string | null | undefined;
  url: string | null | undefined;
};

export default function KreateHead({
  title,
  description,
  imageUrl,
  url,
}: Props) {
  const image = imageUrl || `${CLIENT_ENV.KREATE_ORIGIN}/images/meta.png`;

  return (
    <Head>
      <title //
        key="1409646f-5b57-4ecb-a5ea-d7179e24426c"
      >
        {title}
      </title>
      <meta
        key="36a60a98-87ba-42f4-b264-64e800773724"
        property="og:title"
        content={title}
      />
      {description != null ? (
        <meta
          key="15a702af-6f65-40dd-acec-831c461a7e46"
          property="og:description"
          content={description}
        />
      ) : null}
      <meta
        key="4b634aca-c885-4bf4-9c54-b8f4ab6383f1"
        property="og:image"
        content={image}
      />
      {url != null ? (
        <meta
          key="6d8fb0b6-305e-417c-8ee2-7aa40c8e499e"
          property="og:url"
          content={url}
        />
      ) : null}
      <meta
        key="4b1182c8-6072-4c14-94e3-cdaf02ece330"
        name="twitter:title"
        content={title}
      />
      {description != null ? (
        <meta
          key="00c88771-6127-4755-9ff7-3b3f83c32329"
          name="twitter:description"
          content={description}
        />
      ) : null}
      <meta
        key="ca473bd8-a7a4-492e-8d39-d4bd30ae2f39"
        name="twitter:image"
        content={image}
      />
      <meta
        key="e21baca7-be2d-4aed-9022-39662d6b3014"
        name="twitter:card"
        content="summary_large_image"
      />
    </Head>
  );
}
